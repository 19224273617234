import MySelfResponse from "interfaces/response/MySelfResponse";
import { ApplicationRole } from "shared/enums/feature/ApplicationRole";

export const checkIfUserHasApplicationRole = (
  userApplicationRole: number,
  requiredRole: ApplicationRole
) => {
  return (userApplicationRole & requiredRole) === requiredRole;
};

export const checkIfCurrentUserIsSetupAdmin = (currentUser: MySelfResponse) => {
  return checkIfUserHasApplicationRole(
    currentUser.applicationRole,
    ApplicationRole.TenantSetup
  );
};
