import PromineoButton, {
  PromineoButtonType,
} from "components/common/controls/buttons/PromineoButton";
import PromineoToggleButtonLarge from "components/common/controls/buttons/PromineoToggleButtonLarge";
import PromineoCancelEditingConfirmationDialog from "components/common/controls/PromineoCancelEditingConfirmationDialog";
import PromineoTextBox from "components/common/controls/PromineoTextBox";
import LabelHolder from "components/common/LabelHolder";
import LabelWithContent from "components/common/LabelWithContent";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import PromineoModal from "components/modal/PromineoModal";
import ConfigCreateRequest from "interfaces/request/ConfigCreateRequest";
import LabelResponse from "interfaces/response/LabelResponse";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CONFIGS } from "shared/constants/RoutePathConstants";
import { ConfigDirection } from "shared/enums/feature/ConfigDirection";
import { SupportedLabelEntity } from "shared/enums/feature/SupportedLabelEntity";
import { toastError, toastSuccess } from "shared/utilities/ToastUtility";
import { createNewConfigAsync } from "store/actions/ConfigActions";
import { loadSimplifiedConnectorsAsync } from "store/actions/ConnectorActions";
import { loadSimplifiedTemplatesAsync } from "store/actions/TemplateActions";
import { useAppDispatch, useAppSelector } from "store/hooks";
import ConfigTemplateSelectorDropdown from "./config-edit/edit-config-header/ConfigTemplateSelectorDropdown";
import ConnectorDropdown from "./config-edit/edit-config-header/ConnectorDropdown";

const defaultConfigData: ConfigCreateRequest = {
  title: "",
  connectorId: 0,
  templateId: 0,
  direction: ConfigDirection.Receiving,
  labelIdentifiers: [],
  connectorHostScheduleIdentifiers: [],
  hostSystemParameters: "",
};

interface Props {
  onClose: () => void;
}

export default function NewConfigWizard(props: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [closeConfirmationVisible, setCloseConfirmationVisible] =
    useState(false);
  const [isDirectionChangingEnabled, setIsDirectionChangingEnabled] =
    useState(true);
  const [selectedLabels, setSelectedLabels] = useState<LabelResponse[]>([]);
  const [isValid, setIsValid] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [configData, setConfigData] =
    useState<ConfigCreateRequest>(defaultConfigData);

  const handleCancelClick = () => {
    if (isDirty) {
      setCloseConfirmationVisible(true);
    } else {
      props.onClose();
    }
  };

  const handleSubmitClick = () => {
    if (isValid) {
      displayLoadingPanel();
      dispatch(createNewConfigAsync(configData))
        .unwrap()
        .then((createdConfig) => {
          toastSuccess("Config saved with status: under construction");
          props.onClose();
          navigate(`${CONFIGS}/${createdConfig.id}/edit`);
        })
        .finally(hideLoadingPanel);
    }
  };

  const simplifiedTemplates = useAppSelector(
    (store) => store.templateData.simplifiedTemplates
  );

  const simplifiedConnectors = useAppSelector(
    (store) => store.connectorData.simplifiedConnectors
  );

  useEffect(() => {
    displayLoadingPanel();
    dispatch(loadSimplifiedTemplatesAsync()).finally(hideLoadingPanel);

    displayLoadingPanel();
    dispatch(loadSimplifiedConnectorsAsync()).finally(hideLoadingPanel);
  }, []);

  useEffect(() => {
    if (configData.connectorId && simplifiedConnectors.length) {
      const selectedConnector = simplifiedConnectors.find(
        (con) => con.id === configData.connectorId
      );
      if (selectedConnector) {
        setIsDirectionChangingEnabled(
          selectedConnector.canDownload && selectedConnector.canUpload
        );
      } else {
        setIsDirectionChangingEnabled(true);
      }
    }
  }, [configData, simplifiedConnectors]);

  useEffect(() => {
    setConfigData((prev) => {
      return { ...prev, labelIdentifiers: selectedLabels.map((l) => l.id) };
    });
  }, [selectedLabels]);

  useEffect(() => {
    setIsValid(() => {
      return !!(
        configData.connectorId &&
        configData.templateId &&
        !!configData.title
      );
    });
  }, [configData]);

  const setDirty = () => {
    setIsDirty(true);
  };

  const handleTitleChange = (title: string) => {
    setDirty();
    setConfigData((prev) => {
      return { ...prev, title };
    });
  };

  const handleTemplateSelectionChange = (templateId: number) => {
    setDirty();
    setConfigData((prev) => {
      return { ...prev, templateId };
    });
  };
  const handleConnectorSelectionChange = (connectorId: number) => {
    setDirty();
    setConfigData((prev) => {
      return { ...prev, connectorId };
    });
  };
  const handleDirectionChange = (direction: ConfigDirection) => {
    setDirty();
    setConfigData((prev) => {
      return { ...prev, direction };
    });
  };

  const handleAddNewLabel = (label: LabelResponse) => {
    setDirty();
    setSelectedLabels((prev) => {
      return [...prev, label];
    });
  };
  const handleDeleteLabel = (label: LabelResponse) => {
    setDirty();
    setSelectedLabels((prev) => {
      return prev.filter((l) => l.id !== label.id);
    });
  };

  return (
    <>
      <PromineoModal isVisible={true} height={583} width={728}>
        <div className="flex flex-col justify-between h-[535px] gap-y-4">
          <div className="flex justify-between">
            <div>
              <span className="font-poppins text-[18px] heading-[27px] font-semibold">
                New Config
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-y-4 h-full">
            <div className="border-y border-frameGrey py-4 font-normal text-sm leading-[21px]">
              Give your Config a descriptive title and define what data you want
              to exchange, using which connector and in which direction.
              Optionally, you can add labels to the Config. Click submit to
              continue.
            </div>
            <div>
              <div className="grid grid-col gap-y-4">
                <LabelWithContent
                  label="Config title"
                  addAsterisksymbol={true}
                  content={
                    <PromineoTextBox
                      width={328}
                      name="title"
                      placeholder="Type..."
                      onChange={({ event }: any) => {
                        handleTitleChange(event?.currentTarget?.value);
                      }}
                    />
                  }
                />
                <LabelWithContent
                  label="Template"
                  addAsterisksymbol={true}
                  content={
                    <ConfigTemplateSelectorDropdown
                      simplifiedTemplates={simplifiedTemplates}
                      selectedTemplateId={configData.templateId}
                      onSelectionChange={handleTemplateSelectionChange}
                      width={328}
                    />
                  }
                />
                <LabelWithContent
                  label="Connector"
                  addAsterisksymbol={true}
                  content={
                    <ConnectorDropdown
                      simplifiedConnectors={simplifiedConnectors}
                      onSelectionChanged={handleConnectorSelectionChange}
                      configDirection={configData.direction}
                      selectedConnectorId={configData.connectorId}
                      width={328}
                    />
                  }
                />
                <LabelWithContent
                  label="Direction"
                  addAsterisksymbol={true}
                  content={
                    <PromineoToggleButtonLarge
                      width={328}
                      value={
                        configData.direction === ConfigDirection.Receiving
                          ? "right"
                          : "left"
                      }
                      toggleLeftText="Sending"
                      toggleRightText="Receiving"
                      onToggleLeft={() => {
                        handleDirectionChange(ConfigDirection.Sending);
                      }}
                      onToggleRight={() => {
                        handleDirectionChange(ConfigDirection.Receiving);
                      }}
                      partialDisabled={
                        !isDirectionChangingEnabled ? "right" : undefined
                      }
                    />
                  }
                />
                <LabelWithContent
                  label="Config labels"
                  content={
                    <LabelHolder
                      labels={selectedLabels}
                      labelType={SupportedLabelEntity.Config}
                      allowAdd={true}
                      allowDelete={true}
                      onAddLabel={handleAddNewLabel}
                      onDelete={handleDeleteLabel}
                    />
                  }
                />
              </div>
            </div>
          </div>

          <div>
            <div className="flex justify-between">
              <PromineoButton
                text={"Cancel"}
                variant={PromineoButtonType.Secondary}
                onClick={handleCancelClick}
              />
              <PromineoButton
                text={"Submit"}
                onClick={handleSubmitClick}
                disabled={!isValid}
              />
            </div>
          </div>
        </div>
      </PromineoModal>

      {closeConfirmationVisible && (
        <PromineoCancelEditingConfirmationDialog
          onCancel={() => {
            setCloseConfirmationVisible(false);
          }}
          onConfirm={() => {
            setCloseConfirmationVisible(false);
            props.onClose();
          }}
        />
      )}
    </>
  );
}
