import ConfigCreateRequest from "interfaces/request/ConfigCreateRequest";
import ConfigUpdateRequest from "interfaces/request/ConfigUpdateRequest";
import ConfigDetailResponse from "interfaces/response/ConfigDetailResponse";
import ConfigPublishResponse from "interfaces/response/ConfigPublishResponse";
import ConfigResponse from "interfaces/response/ConfigResponse";
import {
  deleteDataAsync,
  getDataAsync,
  postDataAsync,
  putDataAsync,
} from "./API";
import ConfigForExchangeAgreementResponse from "interfaces/response/ConfigForExchangeAgreementResponse";
import { ConfigDirection } from "shared/enums/feature/ConfigDirection";
import ResponseBase from "interfaces/response/ResponseBase";
import ConfigTemplateUpdatePreviewInfoResponse from "interfaces/response/ConfigTemplateUpdatePreviewInfoResponse";
import ConfigUpdateBasedOnTemplateWriteRequest from "interfaces/request/ConfigUpdateBasedOnTemplateWriteRequest";

export const getConfigsAsync = async () => {
  let apiUrl = "Users/Configs";
  let response = await getDataAsync<ConfigResponse[]>(apiUrl);
  return response;
};

export const getConfigByIdAsync = async (configId: number) => {
  let apiUrl = `Configs/${configId}`;
  let response = await getDataAsync<ConfigDetailResponse>(apiUrl);
  return response;
};

export const createConfigAsync = async (config: ConfigCreateRequest) => {
  let apiUrl = "Configs";
  let response = await postDataAsync<ConfigCreateRequest, ConfigDetailResponse>(
    apiUrl,
    config
  );
  return response;
};

export const updateConfigAsync = async (
  configId: number,
  config: ConfigUpdateRequest
) => {
  let apiUrl = `Configs/${configId}`;
  let response = await putDataAsync<ConfigUpdateRequest, ConfigDetailResponse>(
    apiUrl,
    config,
    false
  );
  return response;
};

export const publishConfigAsync = async (configId: number) => {
  let apiUrl = `Configs/${configId}/Publish`;
  let response = await postDataAsync<any, ConfigPublishResponse>(
    apiUrl,
    null,
    false
  );
  return response;
};

export const deleteConfigAsync = async (configId: number) => {
  let apiUrl = `Configs/${configId}`;
  let response = await deleteDataAsync(apiUrl);
  return response;
};

export const getOwnerConfigForExchangeAgreementAsync = async () => {
  const apiUrl = `Users/Configs/ForExchangeAgreement`;

  return await getDataAsync<ConfigForExchangeAgreementResponse[]>(apiUrl);
};

export const getPartnerConfigForExchangeAgreementAsync = async (
  templateId: number,
  direction: ConfigDirection
) => {
  const apiUrl = `Users/Templates/${templateId}/Configs/${direction}/ForExchangeAgreement`;

  return await getDataAsync<ConfigForExchangeAgreementResponse[]>(apiUrl);
};

export const getConfigForExchangeAgreementAsync = async (
  templateId: number,
  connectorId: number,
  connectorScheduleId: number,
  configDirection: ConfigDirection
) => {
  const apiUrl = `Users/Templates/${templateId}/Connectors/${connectorId}/ConnectorSchedules/${connectorScheduleId}/Configs/${configDirection}/ForExchangeAgreement`;

  return await getDataAsync<ConfigResponse>(apiUrl);
};

export const copyConfigAsync = async (configId: number) => {
  let apiUrl = `Configs/${configId}/Copy`;
  let response = await postDataAsync<any, ResponseBase>(apiUrl, null);

  return response;
};

export const getConfigTemplateChangesPreviewAsync = async (
  configId: number
) => {
  let apiUrl = `Configs/${configId}/Template/Changes`;
  let response = await getDataAsync<ConfigTemplateUpdatePreviewInfoResponse>(
    apiUrl
  );
  return response;
};

export const updateConfigAcceptingTemplateChangesAsync = async (
  configId: number,
  configUpdateRequest: ConfigUpdateBasedOnTemplateWriteRequest
) => {
  let apiUrl = `Configs/${configId}/Template/Changes`;
  let response = await putDataAsync<
    ConfigUpdateBasedOnTemplateWriteRequest,
    ConfigDetailResponse
  >(apiUrl, configUpdateRequest);
  return response;
};
