import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import { useEffect, useState } from "react";
import LabelWithContent from "../../components/common/LabelWithContent";
import IEAContentBodyWithTitle from "./IEAContentBodyWithTitle";

import { getScheduleLabel } from "shared/utilities/IEAUtility";
import OwnerRepresentativeField from "./active-ieas/OwnerRepresentativeField";
import ConfigNameDisplay from "./ConfigNameDisplay";

interface Props {
  isOwner: boolean;
  exchangeAgreement: ExchangeAgreementDetailedResponse;
  handleOwnerRepresentativeChange: (representativeIds: number[]) => void;
  onSaveChangesClick?: () => void;
  isActiveIeaEdit?: boolean;
  allowEdit?: boolean;
}

export default function OwnerConfiguration(props: Props) {
  const {
    isOwner,
    exchangeAgreement,
    handleOwnerRepresentativeChange,
    onSaveChangesClick,
  } = props;

  const labelClassName =
    "ml-2 w-full pr-6 font-inter font-medium text-[12px] leading-15px";

  const [ownerRepresentativeIds, setOwnerRepresentativeIds] = useState<
    number[]
  >([]);

  useEffect(() => {
    if (isOwner) {
      const userIdentifiers = exchangeAgreement.ownerRepresentatives.map(
        (user) => user.id
      );
      setOwnerRepresentativeIds(userIdentifiers);
    }
  }, [isOwner, exchangeAgreement.ownerRepresentatives]);

  const handleSaveOwnerRepresentativeClick = () => {
    if (onSaveChangesClick) {
      onSaveChangesClick();
    }
  };

  return (
    <IEAContentBodyWithTitle
      title="Owner configuration"
      bodyHeight="h-auto"
      content={
        <>
          <div className="grid grid-cols-4 gap-y-4">
            <LabelWithContent
              label="Owner tenant"
              content={
                <div className={labelClassName}>
                  {exchangeAgreement.ownerTenant?.id}{" "}
                  {exchangeAgreement.ownerTenant?.name}
                </div>
              }
            />
            <LabelWithContent
              label="Connector name"
              content={
                <div className={labelClassName}>
                  {exchangeAgreement.ownerConnector?.name}
                </div>
              }
            />
            <LabelWithContent
              label="Host System"
              content={
                <div className={labelClassName}>
                  {exchangeAgreement.ownerConnector?.hostSystemName}
                </div>
              }
            />
            <LabelWithContent
              label="Config"
              content={
                <div className={labelClassName}>
                  <ConfigNameDisplay
                    configId={exchangeAgreement.ownerConfigId}
                    configTitle={exchangeAgreement.ownerConfigTitle}
                    tenantId={exchangeAgreement.ownerTenant?.id}
                  />
                </div>
              }
            />
            <LabelWithContent
              label="Exchange role"
              content={
                <div className={labelClassName}>
                  {exchangeAgreement.ownerRoleText}
                </div>
              }
            />
            <div className="grid gap-4">
              <LabelWithContent
                label="Schedule identifier"
                content={
                  <div className={labelClassName}>
                    {getScheduleLabel(exchangeAgreement.ownerSchedule)}
                  </div>
                }
              />
            </div>

            <LabelWithContent
              label="Execute component"
              content={
                <div className={labelClassName}>
                  {exchangeAgreement.ownerConnector?.executionComponentText}
                </div>
              }
            />

            <div className="space-y-2">
              <OwnerRepresentativeField
                ownerTenantId={exchangeAgreement?.ownerTenant?.id}
                editConfig={{
                  onOwnerRepresentativeChange: handleOwnerRepresentativeChange,
                  selectedRepresentatives:
                    exchangeAgreement.ownerRepresentatives,
                  ownerRepresentativeIds: ownerRepresentativeIds,
                  isOwner: isOwner,
                  onSaveOwnerRepresentative: handleSaveOwnerRepresentativeClick,
                  allowEdit: isOwner && props.allowEdit,
                }}
                createConfig={{
                  onValueChange: handleOwnerRepresentativeChange,
                  ownerRepresentatives:
                    exchangeAgreement.ownerRepresentatives.map((r) => r.id),
                }}
                editStatus={props.isActiveIeaEdit ? "create" : "edit"}
              />
            </div>
          </div>
        </>
      }
    />
  );
}
