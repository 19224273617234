import { DropdownOptions } from "components/common/dropdown-box/PromineoDropdownBox";
import PromineoTreeViewDropdown from "components/common/tree-view-dropdown/PromineoTreeViewDropdown";
import { TreeViewOptions } from "components/common/tree-view/PromineoTreeView";
import { IDropDownBoxOptions } from "devextreme-react/drop-down-box";
import { ITreeViewOptions } from "devextreme-react/tree-view";
import ConnectorBaseResponse from "interfaces/response/connector/ConnectorBaseResponse";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ConnectorAndTemplateDropdownWidth } from "shared/constants/ConfigUIConstants";
import { ConfigDirection } from "shared/enums/feature/ConfigDirection";

interface Props {
  onSelectionChanged: (connectorId: number, connectorName: string) => void;
  selectedConnectorId?: number;
  configDirection?: ConfigDirection;
  simplifiedConnectors: ConnectorBaseResponse[];
  width?: number;
}

export default function ConnectorDropdown(props: Props) {
  const [connectorSource, setConnectorSource] = useState<any>();

  const getFilteredConnectors = useCallback(
    (connectors: ConnectorBaseResponse[]): ConnectorBaseResponse[] => {
      switch (props.configDirection) {
        case ConfigDirection.Sending:
          return connectors.filter((c) => c.canUpload);
        case ConfigDirection.Receiving:
          return connectors.filter((c) => c.canDownload);
        default:
          return [...connectors];
      }
    },
    [props.simplifiedConnectors, props.configDirection]
  );

  useEffect(() => {
    if (props.simplifiedConnectors) {
      let groupedConnectors: any[] = [];

      const filteredConnectors = getFilteredConnectors(
        props.simplifiedConnectors
      );

      const hostNames = filteredConnectors.map((c) => c.hostSystemName);
      const distinctHostNames = hostNames.filter(
        (value, index, array) => array.indexOf(value) === index
      );
      distinctHostNames.forEach((conName) => {
        groupedConnectors.push({ id: conName, name: conName });
      });

      filteredConnectors.forEach((connector) => {
        groupedConnectors.push({
          ...connector,
          parent: connector.hostSystemName,
        });
      });
      setConnectorSource(groupedConnectors);
    }
  }, [getFilteredConnectors]);

  const handleSelectionChanged = (data: any) => {
    if (data && data.length) {
      const name =
        props.simplifiedConnectors.find((c) => c.id === data[0])?.name ?? "";
      props.onSelectionChanged(data[0], name);
    }
  };

  const treeViewOptions = useRef<TreeViewOptions & ITreeViewOptions>({
    showCheckBoxesMode: "none",
    selectionMode: "single",
    selectByClick: true,
    displayExpr: "name",
    dataStructure: "plain",
    parentIdExpr: "parent",
    keyExpr: "id",
  });

  const checkIfIsSelectable = useCallback((data: any) => {
    return !!data.parent;
  }, []);

  const dropdownOptions = useMemo<DropdownOptions & IDropDownBoxOptions>(() => {
    return {
      displayExpr: "name",
      valueExpr: "id",
      borderRound: "small",
      placeholder: "Select",
      value: [props.selectedConnectorId],
      width: props.width ?? ConnectorAndTemplateDropdownWidth,
    };
  }, [props.selectedConnectorId, props.width]);

  return (
    <PromineoTreeViewDropdown
      treeViewOptions={treeViewOptions.current}
      checkIfIsSelectable={checkIfIsSelectable}
      dataSource={connectorSource}
      shouldClosePopupOnSelect={true}
      onSelectionChanged={handleSelectionChanged}
      popupWidth={props.width ?? ConnectorAndTemplateDropdownWidth}
      dropdownOptions={dropdownOptions}
      preventTogggleSelection={true}
    />
  );
}
