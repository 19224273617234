import TemplateSelectorDropdown, {
  TemplateDropdownSourceType,
} from "features/common/TemplateSelectorDropdown";
import TemplateDropdownResponse from "interfaces/response/TemplateDropdownResponse";
import { useEffect, useState } from "react";

interface Props {
  onSelectionChange: (templateId: number, title: string) => void;
  simplifiedTemplates: TemplateDropdownResponse | null;
  selectedTemplateId?: number;
  width?: number;
}

export default function ConfigTemplateSelectorDropdown(props: Props) {
  const [templateDataSource, setTemplateDataSource] =
    useState<TemplateDropdownSourceType>({ own: [], received: [] });

  useEffect(() => {
    setTemplateDataSource({
      own: props.simplifiedTemplates?.own ?? [],
      received: props.simplifiedTemplates?.received ?? [],
    });
  }, [props.simplifiedTemplates]);

  return (
    <TemplateSelectorDropdown
      templateDataSource={templateDataSource}
      onSelectionChange={props.onSelectionChange}
      selectedTemplateId={props.selectedTemplateId}
      width={props.width}
    />
  );
}
