import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import NoTenantDialogs from "./NoTenantDialogs";
import TenantRequestRejectedDialog from "./TenantRequestRejectedDialog";
import TenantUserDisabledDialog from "./TenantUserDisabledDialog";
import { resetNoTenantErrorMessage, resetUserDisabledErrorMessage } from "store/slices/ErrorSlice";

interface Props {}

export default function UserTenantVerification(props: Props) {
  const dispatch = useAppDispatch();
  const [displayTenantRegisterDialog, setDisplayTenantRegisterDialog] = useState(false);
  const [displayTenantRequestRejectedDialog, setDisplayTenantRequestRejectedDialog] = useState(false);
  const [displayUserDisabledDialog, setDisplayUserDisabledDialog] = useState(false);

  const noTenantFoundErrorMessage = useAppSelector(
    (store) => store.errorData.noTenantFoundErrorMessage
  );

  const userDisabledErrorMessage = useAppSelector(
    (store) => store.errorData.userDisabledErrorMessage
  );

  useEffect(() => {
    if (noTenantFoundErrorMessage) {
      setDisplayTenantRegisterDialog(true);
    }
    else if(userDisabledErrorMessage){
      setDisplayUserDisabledDialog(true);
    }
    // Keeping these commented for future implementation.
    // if (userLookUpData.tenantStatus === UserTenantStatus.NoTenant) {
    //   setDisplayTenantRegisterDialog(true);
    // } else if (userLookUpData.tenantStatus === UserTenantStatus.Requested) {
    //   setDisplayTenantRequestedDialog(true);
    // } else if (userLookUpData.tenantStatus === UserTenantStatus.Rejected) {
    //   setDisplayTenantRequestRejectedDialog(true);
    // } else {
    //   if (!userLookUpData.isActive) {
    //     setDisplayUserDisabledDialog(true);
    //   }
    // }
  }, [noTenantFoundErrorMessage, userDisabledErrorMessage]);

  const handleCloseClick = () => {
    setDisplayTenantRegisterDialog(false);
    setDisplayTenantRequestRejectedDialog(false);
    setDisplayUserDisabledDialog(false);
    dispatch(resetNoTenantErrorMessage());
    dispatch(resetUserDisabledErrorMessage());
  };

  if (displayTenantRegisterDialog) {
    return (
      <NoTenantDialogs
        onClose={handleCloseClick}
        message={noTenantFoundErrorMessage}
      />
    );
  }

  if (displayTenantRequestRejectedDialog) {
    return <TenantRequestRejectedDialog onClose={handleCloseClick} />;
  }

  if (displayUserDisabledDialog) {
    return <TenantUserDisabledDialog onClose={handleCloseClick}  />;
  }

  return null;
}
