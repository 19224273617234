import { useEffect, useState } from "react";

interface Props {
  toggleLeftText: string;
  toggleRightText: string;
  onToggleLeft: () => void;
  onToggleRight: () => void;
  value?: "left" | "right";
  width?: number;
  disabled?: boolean;
  partialDisabled?: "left" | "right";
}

const ToggleButton = (props: {
  text: string;
  onClick: () => void;
  isOn: boolean;
  isLeft: boolean;
  disabled?: boolean;
  partialDisabled?: "left" | "right";
}) => {
  const toggleOnClasses = `border  ${
    props.isLeft ? "rounded-l" : "rounded-r"
  } ${
    props.disabled
      ? "bg-frameGrey border-frameGrey text-gray"
      : "bg-lighterBlue border-ilapLightBlue text-ilapBlue"
  }`;
  return (
    <div
      onClick={props.onClick}
      className={`w-full h-8 justify-center items-center flex text-center cursor-pointer py-1 px-6px text-xs font-normal font-poppins leading-[18px] ${
        props.isOn
          ? toggleOnClasses
          : `bg-white border border-y-lightGray ${
              props.isLeft
                ? "rounded-l border-l-lightGray border-r-transparent"
                : "rounded-r border-r-lightGray border-l-transparent"
            } ${
              !props.disabled
                ? "hover:text-ilapBlue"
                : "text-gray border-frameGrey"
            }`
      }`}
    >
      {props.text}
    </div>
  );
};

export default function PromineoToggleButtonLarge(props: Props) {
  const [toggleState, setToggleState] = useState(
    props.value === "right" ? true : false
  );

  const isLeftToggleDisabled =
    props.partialDisabled !== undefined && props.partialDisabled === "left";
  const isRightToggleDisabled =
    props.partialDisabled !== undefined && props.partialDisabled === "right";

  useEffect(() => {
    setToggleState(props.value === "right" ? true : false);
  }, [props.value]);

  const handleToggleLeft = () => {
    if (!props.disabled && !isLeftToggleDisabled) {
      setToggleState(false);
      props.onToggleLeft();
    }
  };

  const handleToggleRight = () => {
    if (!props.disabled && !isRightToggleDisabled) {
      setToggleState(true);
      props.onToggleRight();
    }
  };

  return (
    <div className="inline-block" style={{ width: props.width ?? "auto" }}>
      <div className={`flex`}>
        <ToggleButton
          text={props.toggleLeftText}
          onClick={handleToggleLeft}
          isOn={toggleState === false}
          isLeft={true}
          disabled={props.disabled || isLeftToggleDisabled}
        />
        <ToggleButton
          text={props.toggleRightText}
          onClick={handleToggleRight}
          isOn={toggleState === true}
          isLeft={false}
          disabled={props.disabled || isRightToggleDisabled}
        />
      </div>
    </div>
  );
}
