import { createSlice } from "@reduxjs/toolkit";

export interface ErrorState {
  noTenantFoundErrorMessage: string;
  userDisabledErrorMessage: string;
}

const initialState: ErrorState = {
  noTenantFoundErrorMessage: "",
  userDisabledErrorMessage: ""
};

export const errorSlice = createSlice({
  name: "errorHandlerSlice",
  initialState,
  reducers: {
    handleNoTenantError(state, action) {
      state.noTenantFoundErrorMessage = action.payload;
    },
    resetNoTenantErrorMessage(state) {
      state.noTenantFoundErrorMessage = "";
    },
    handleUserDisabledError(state, action) {
      state.userDisabledErrorMessage = action.payload;
    },
    resetUserDisabledErrorMessage(state) {
      state.userDisabledErrorMessage = "";
    },
  },
  extraReducers: (builder) => {},
});

export const {
  handleNoTenantError,
  resetNoTenantErrorMessage,
  handleUserDisabledError,
  resetUserDisabledErrorMessage,
} = errorSlice.actions;

export default errorSlice.reducer;
