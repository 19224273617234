import AddIcon from "components/icons/AddIcon";
import { List } from "devextreme-react";
import { Offset, Position } from "devextreme-react/popover";
import { useCallback, useEffect, useRef, useState } from "react";
import PromineoList from "../list/PromineoList";
import PromineoPopover from "../PromineoPopover";
import LabelResponse from "interfaces/response/LabelResponse";
import { SupportedLabelEntity } from "shared/enums/feature/SupportedLabelEntity";

interface Props {
  allLabels : LabelResponse[];
  onAddNew?: (value: string) => void;
  onSelectionChanged?: (value: any) => void;
  labelType: SupportedLabelEntity;
  alreadyAddedLabels: LabelResponse[];
}

export default function AddLabelButton(props: Props) {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [isCreateNewButtonVisible, setIsCreateNewButtonVisible] = useState(false);
  const [searchText, setSearchText] = useState<string>("");
  const listRef = useRef<List>(null);
  const [availableLabelsForSelection, setAvailableLabelsForSelection] = useState<LabelResponse[]>([]);

  useEffect(() => {
    var alreadySelectedLabelIds = props.alreadyAddedLabels.map((l) => l.id);
    var filteredLablesForSelection = props.allLabels.filter(
      (l) => !alreadySelectedLabelIds.includes(l.id)
    );
    setAvailableLabelsForSelection(filteredLablesForSelection);
  }, [props.allLabels, props.alreadyAddedLabels]);

  const handleCreateNewItemClick = () => {
    props.onAddNew?.(searchText);
    setIsPopoverVisible(false);
    listRef.current?.instance.option("searchValue", "");
  };

  const handleAddButtonClick = useCallback(() => {
    setIsPopoverVisible(true);
  }, []);

  const handleHiding = useCallback(() => {
    setIsPopoverVisible(false);
  }, []);

  const handleSelectedItemsChange = (data: any) => {
    if (data && data.length) {
      setIsPopoverVisible(false);
      props.onSelectionChanged?.(data);
    }
  };

  var temporarySearchText: string = "";
  const handleOptionChanged = (option: any) => {
    if (option.name === "searchValue") {
      temporarySearchText = option.value;
      setSearchText(option.value);
    }
    if (option.name === "items") {
      if (temporarySearchText !== null) {
        var searchedIndex = option.value.findIndex(
          (val: any) =>
            val.name !== null &&
            temporarySearchText.toLowerCase() === val.name.toLowerCase()
        );
        setIsCreateNewButtonVisible(searchedIndex === -1);
      }
    }
  };

  const popupPosition = useRef({ x: "right", y: "top" });

  return (
    <div className="flex items-center bg-transparent px-2">
      <div
        id="add-btn"
        className="h-full cursor-pointer flex items-center justify-center"
        onClick={handleAddButtonClick}
      >
        <div>
          <AddIcon fillColor="blue" />
        </div>{" "}
        <div className="ml-1 font-poppins text-sm font-semibold text-ilapBlue">Add labels</div>
      </div>
      <PromineoPopover
        width={"160"}
        height={"auto"}
        maxHeight={"350"}
        target="#add-btn"
        onHiding={handleHiding}
        visible={isPopoverVisible}
      >
        <Position my={popupPosition.current}>
          <Offset x={20} y={2} />
        </Position>
        <PromineoList
          ref={listRef}
          searchMode="contains"
          height={280}
          searchEnabled={true}
          onOptionChanged={handleOptionChanged}
          dataSource={availableLabelsForSelection}
          displayExpr={"name"}
          keyExpr={"id"}
          searchExpr={"name"}
          selectionMode={"single"}
          onSelectedItemsChange={handleSelectedItemsChange}
        />
        {isCreateNewButtonVisible && searchText && (
          <div
            className="border-t flex items-center justify-center space-x-1 py-2 cursor-pointer hover:bg-gray2"
            onClick={handleCreateNewItemClick}
          >
            <AddIcon fillColor="black" />
            <div className="font-medium text-xs text-ellipsis overflow-hidden">
              Create {`"${searchText}"`}
            </div>
          </div>
        )}
      </PromineoPopover>
    </div>
  );
}
