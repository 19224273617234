import { getDataAsync, postDataAsync, putDataAsync } from "./API";
import OwnTenantResponse from "interfaces/response/OwnTenantResponse";
import OwnTenantWriteRequest from "interfaces/request/OwnTenantWriteRequest";
import TemplateFieldUsageResponse from "interfaces/response/TemplateFieldUsageResponse";
import FieldDetailsResponse from "interfaces/response/FieldDetailsResponse";
import TenantAdminFieldResponse from "interfaces/response/TenantAdminFieldResponse";
import TenantLevelContentControlChangeRequest from "interfaces/request/TenantLevelContentControlChangeRequest";
import TenantLevelContentControlChangeResponse from "interfaces/response/TenantLevelContentControlChangeResponse";
import FieldManageRequest from "interfaces/request/FieldManageRequest";
import TenantUserResponse from "interfaces/response/TenantUserResponse";
import TenantUserUpdateRequest from "interfaces/common/TenantUserUpdateRequest";
import UserStatusRequestResponse from "interfaces/common/UserStatusRequestResponse";
import TenantUserUpdateResponse from "interfaces/common/TenantUserUpdateResponse";

export const loadAdminTenantAsync = async () => {
  const url = "Tenants/Own";

  return getDataAsync<OwnTenantResponse>(url);
};

export const saveAdminTenantAsync = async (
  tenantId: number,
  tenantInfoUpdateRequst: OwnTenantWriteRequest
) => {
  const url = `Tenants/${tenantId}`;

  return putDataAsync<OwnTenantWriteRequest, OwnTenantResponse>(
    url,
    tenantInfoUpdateRequst
  );
};

export const requestOwnerStatusAsync = async () => {};

export const loadContentControlFieldsForTenantAdminAsync = async () => {
  const url = "Fields/TenantLevel";
  const response = getDataAsync<TenantAdminFieldResponse[]>(url);
  return response;
};

export const loadTemplatesUsingFieldForTenantAdminAsync = async (
  fieldId: number
) => {
  const url = `Fields/${fieldId}/Templates`;
  const response = getDataAsync<TemplateFieldUsageResponse[]>(url);
  return response;
};

export const loadFieldDetailForTenantAdminAsync = async (fieldId: number) => {
  const url = `Fields/${fieldId}/Details/TenantLevel`;
  const response = await getDataAsync<FieldDetailsResponse>(url);
  return response;
};

export const changeFieldsContentControlForTenantAdminAsync = async (
  fieldId: number,
  tenantLevelContentControlChangeRequest: TenantLevelContentControlChangeRequest
) => {
  const url = `Fields/${fieldId}/ContentControl/TenantLevel`;
  const response = putDataAsync<
    TenantLevelContentControlChangeRequest,
    TenantLevelContentControlChangeResponse
  >(url, tenantLevelContentControlChangeRequest);
  return response;
};

export const modifyTenantFieldWithValuesAsync = async (
  fieldId: number,
  fieldManageRequest: FieldManageRequest
) => {
  const url = `TenantFields/${fieldId}/Manage`;
  const response = postDataAsync<FieldManageRequest, FieldDetailsResponse>(
    url,
    fieldManageRequest
  );
  return response;
};

export const modifyTemplateFieldWithValuesAsync = async (
  fieldId: number,
  fieldManageRequest: FieldManageRequest
) => {
  const url = `TemplateFields/${fieldId}/Manage`;
  const response = postDataAsync<FieldManageRequest, FieldDetailsResponse>(
    url,
    fieldManageRequest
  );
  return response;
};

export const getTenantUsersAsync = async (excludeSelf : boolean = false, includeSystemUsers : boolean = false) => {
  let apiUrl = `Tenant/Users?excludeSelf=${excludeSelf}&includeSystemUsers=${includeSystemUsers}`;
  
  return await getDataAsync<TenantUserResponse[]>(apiUrl);
};

export const modifyUserAsync = async (
  userId: number,
  request: TenantUserUpdateRequest
) => {
  const apiUrl = `Tenant/Users/${userId}`;
  return await putDataAsync<TenantUserUpdateRequest, TenantUserUpdateResponse>(
    apiUrl,
    request
  );
};

export const modifyUserStatusAsync = async (
  userId: number,
  request: UserStatusRequestResponse
) => {
  const apiUrl = `Tenant/Users/${userId}/Status`;
  return await putDataAsync<
    UserStatusRequestResponse,
    UserStatusRequestResponse
  >(apiUrl, request);
};
