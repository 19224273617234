import { getTokenAsync } from "authentication/AuthenticationManager";
import {
  AxiosError,
  AxiosInstance,
  InternalAxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { getApiUrl } from "shared/utilities/ConfigUtility";
import { handleNoTenantError, handleUserDisabledError } from "store/slices/ErrorSlice";
import { parseErrorResponse } from "./ErrorParser";

export function WithInterceptors(axiosInstance: AxiosInstance) {
  axiosInstance.interceptors.response.use(
    handleSuccessfulRequest,
    handleFailedRequest
  );
  return axiosInstance;
}

export function WithAuthenticationInterceptors(axiosInstance: AxiosInstance) {
  axiosInstance.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
    if (
      config.baseURL === getApiUrl() &&
      !(config && config.headers && config.headers.Authorization)
    ) {
      const token = await getTokenAsync();
      if (token && config && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  });

  axiosInstance.interceptors.response.use(
    handleSuccessfulRequest,
    handleFailedRequest
  );
  return axiosInstance;
}

function handleSuccessfulRequest(response: AxiosResponse<any>) {
  return response;
}

function handleFailedRequest(error: AxiosError<any>) {
  const parsedError = parseErrorResponse(error);
  return Promise.reject(parsedError);
}

export const errorMiddleware = (store: any) => (next: any) => (action: any) => {
  if (action.error && action.error.message) {
    const message: string = action.error.message;
    const tenantNotFoundMessageParts =
      "Please contact with support to register your organization";
    const userIsDisabledMessageParts = "Your user has been disabled";

    if (message.indexOf(tenantNotFoundMessageParts) >= 0) {
      store.dispatch(handleNoTenantError(action.error.message));
    }
    else if (message.indexOf(userIsDisabledMessageParts) >= 0) {
      store.dispatch(handleUserDisabledError(action.error.message));
    }
  }

  return next(action);
};
