import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import PromineoSelectBox from "components/common/controls/PromineoSelectBox";
import ConfigForExchangeAgreementResponse from "interfaces/response/ConfigForExchangeAgreementResponse";
import ConfigResponse from "interfaces/response/ConfigResponse";
import ConnectorScheduleSimplifiedResponse from "interfaces/response/ConnectorScheduleSimplifiedResponse";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import ConnectorBaseResponse from "interfaces/response/connector/ConnectorBaseResponse";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ConfigDirection } from "shared/enums/feature/ConfigDirection";
import { DirectionEnum } from "shared/enums/feature/DirectionEnum";
import { NewExchangeAgreementActionRequired } from "shared/enums/feature/NewExchangeAgreementActionRequired";
import {
  getDisplayExpressionForScheduleSelectorDropdown,
  getScheduleLabel,
} from "shared/utilities/IEAUtility";
import { loadPartnerConfigForExchangeAgreementAsync } from "store/actions/ConfigActions";
import { loadExportScopeValuesAsync } from "store/actions/DropdownValueActions";
import { AppDispatch, RootState } from "store/store";
import LabelWithContent from "../../components/common/LabelWithContent";
import ConfigNameDisplay from "./ConfigNameDisplay";
import IEAContentBodyWithTitle from "./IEAContentBodyWithTitle";
import ScheduleSelectionDropdownItemRenderComponent from "./ScheduleSelectionDropdownItemRenderComponent";
import PartnerRepresentativeField from "./active-ieas/PartnerRepresentativeField";

interface Props {
  exchangeAgreement: ExchangeAgreementDetailedResponse;
  isPartner: boolean;
  partnerConfigSettings?: ConfigResponse | null;
  selectedPartnerConnectorId?: number;
  selectedPartnerScheduleId?: number;
  handlePartnerRepresentativeChange: (representativeIds: number[]) => void;
  handlePartnerConnectorChange?: (connector: ConnectorBaseResponse) => void;
  handlePartnerScheduleChange?: (
    selectedItem: ConnectorScheduleSimplifiedResponse
  ) => void;
  handleSavePartnerRepresentativeClick?: () => void;
  isActiveIeaEdit?: boolean;
  allowEdit?: boolean;
}

export default function PartnerConfiguration(props: Props) {
  const {
    exchangeAgreement,
    isPartner,
    selectedPartnerConnectorId,
    handlePartnerRepresentativeChange,
    handlePartnerConnectorChange,
    handlePartnerScheduleChange,
    handleSavePartnerRepresentativeClick,
    selectedPartnerScheduleId,
  } = props;

  const dispatch = useDispatch<AppDispatch>();
  const labelClassName =
    "ml-2 w-full pr-6 font-inter font-medium text-[12px] leading-15px";

  const [partnerRepresentativeIds, setPartnerRepresentativeIds] = useState<
    number[]
  >([]);
  const [requiredAction, setRequiredAction] = useState(
    exchangeAgreement.actionRequiredIfInactive
  );
  const [selectedPartnerConnector, setSelectedPartnerConnector] =
    useState<ConnectorBaseResponse>(exchangeAgreement.partnerConnector);
  const [connectorDataSource, setConnectorDataSource] = useState<
    ConnectorBaseResponse[]
  >([]);
  const [scheduleDataSource, setScheduleDataSource] = useState<
    ConnectorScheduleSimplifiedResponse[]
  >([]);
  const [
    selectedConfigBasedOnPartnerConnectorAndSchedule,
    setSelectedConfigBasedOnPartnerConnectorAndSchedule,
  ] = useState<ConfigForExchangeAgreementResponse>();

  const partnerConfiguration = useSelector(
    (state: RootState) => state.configData.partnerConfigForExchangeAgreement
  );

  useEffect(() => {
    displayLoadingPanel();
    dispatch(loadExportScopeValuesAsync()).finally(hideLoadingPanel);
  }, [dispatch]);

  useEffect(() => {
    setRequiredAction(exchangeAgreement.actionRequiredIfInactive);
  }, [exchangeAgreement]);

  useEffect(() => {
    if (isPartner) {
      if (requiredAction === NewExchangeAgreementActionRequired.Complete) {
        if (exchangeAgreement.template) {
          // If Direction is OwnerToPartner,then owner is "Sender" and partner is "Receiver".
          // If Direction is PartnerToOwner,then partner is "Sender" and owner is "Receiver".

          let partnerDirection = ConfigDirection.Receiving;
          if (exchangeAgreement.direction === DirectionEnum.PartnerToOwner) {
            partnerDirection = ConfigDirection.Sending;
          }

          displayLoadingPanel();
          dispatch(
            loadPartnerConfigForExchangeAgreementAsync({
              templateId: exchangeAgreement.template.id,
              direction: partnerDirection,
            })
          ).finally(hideLoadingPanel);
        }
      }

      const userIdentifiers = exchangeAgreement.partnerRepresentatives.map(
        (user) => user.id
      );
      setPartnerRepresentativeIds(userIdentifiers);
    }
  }, [isPartner, requiredAction, exchangeAgreement.partnerTenant]);

  useEffect(() => {
    if (
      selectedPartnerConnectorId &&
      selectedPartnerScheduleId &&
      partnerConfiguration
    ) {
      partnerConfiguration.forEach((config) => {
        if (config.connector.id === selectedPartnerConnectorId) {
          config.connectorSchedules.forEach((schedule) => {
            if (schedule.id === selectedPartnerScheduleId) {
              setSelectedConfigBasedOnPartnerConnectorAndSchedule(config);
              return;
            }
          });
        }
      });
    } else {
      setSelectedConfigBasedOnPartnerConnectorAndSchedule(undefined);
    }
  }, [selectedPartnerConnectorId, selectedPartnerScheduleId]);

  useEffect(() => {
    if (
      isPartner &&
      requiredAction === NewExchangeAgreementActionRequired.Complete
    ) {
      const temporaryDataSource: ConnectorBaseResponse[] = [];

      for (let index = 0; index < partnerConfiguration.length; index++) {
        temporaryDataSource.push(partnerConfiguration[index].connector);
      }

      // Filtering for Unique values.
      let filteredDataSource = temporaryDataSource.filter(
        (data, ind) =>
          ind === temporaryDataSource.findIndex((val) => val.id === data.id)
      );

      setConnectorDataSource(filteredDataSource);
    }
  }, [isPartner, requiredAction, partnerConfiguration]);

  useEffect(() => {
    if (selectedPartnerConnectorId) {
      const temporaryDataSource: ConnectorScheduleSimplifiedResponse[] = [];

      for (let index = 0; index < partnerConfiguration.length; index++) {
        if (
          partnerConfiguration[index].connector?.id ===
          selectedPartnerConnectorId
        ) {
          temporaryDataSource.push(
            ...partnerConfiguration[index].connectorSchedules
          );
        }
      }

      // Filtering for Unique values.
      let filteredDataSource = temporaryDataSource.filter(
        (data, ind) =>
          ind === temporaryDataSource.findIndex((val) => val.id === data.id)
      );

      setScheduleDataSource(filteredDataSource);
    }
  }, [selectedPartnerConnectorId]);

  useEffect(() => {
    if (selectedPartnerConnectorId && connectorDataSource) {
      var connector = connectorDataSource.filter(
        (cn) => cn.id === selectedPartnerConnectorId
      );
      if (connector.length !== 0) {
        setSelectedPartnerConnector(connector[0]);
      }
    }
  }, [connectorDataSource, selectedPartnerConnectorId]);

  const handleConnectorSelectionChange = (connectorId: number) => {
    var connector = connectorDataSource.filter((cn) => cn.id === connectorId);
    if (connector.length !== 0) {
      handlePartnerConnectorChange?.(connector[0]);
    }
  };

  const RenderConnectorNameComponent = useCallback(() => {
    return (
      <>
        {isPartner &&
        requiredAction === NewExchangeAgreementActionRequired.Complete ? (
          <LabelWithContent
            label="Connector name"
            addAsterisksymbol={true}
            content={
              <PromineoSelectBox
                placeholder="Select"
                value={selectedPartnerConnectorId}
                valueExpr={"id"}
                items={connectorDataSource}
                displayExpr={"name"}
                onValueChange={handleConnectorSelectionChange}
              />
            }
          />
        ) : (
          <LabelWithContent
            label="Connector name"
            content={
              <div className={labelClassName}>
                {exchangeAgreement.partnerConnector
                  ? exchangeAgreement.partnerConnector.name
                  : "-"}
              </div>
            }
          />
        )}
      </>
    );
  }, [
    isPartner,
    requiredAction,
    connectorDataSource,
    selectedPartnerConnectorId,
  ]);

  const RenderScheduleIdentifierComponent = useCallback(() => {
    return (
      <>
        {isPartner &&
        requiredAction === NewExchangeAgreementActionRequired.Complete ? (
          <LabelWithContent
            label="Schedule identifier"
            addAsterisksymbol={true}
            content={
              <PromineoSelectBox
                placeholder="Select"
                disabled={
                  selectedPartnerConnectorId
                    ? selectedPartnerConnectorId === 0
                    : true
                }
                items={scheduleDataSource}
                itemRender={ScheduleSelectionDropdownItemRenderComponent}
                valueExpr={"id"}
                displayExpr={getDisplayExpressionForScheduleSelectorDropdown}
                onSelectionChanged={(selection: any) =>
                  handlePartnerScheduleChange?.(selection.selectedItem)
                }
              />
            }
          />
        ) : (
          <LabelWithContent
            label="Schedule identifier"
            content={
              <div className={labelClassName}>
                {getScheduleLabel(exchangeAgreement.partnerSchedule)}
              </div>
            }
          />
        )}
      </>
    );
  }, [
    isPartner,
    requiredAction,
    selectedPartnerConnectorId,
    scheduleDataSource,
  ]);

  const RenderHostSystemComponent = useCallback(() => {
    return (
      <>
        {isPartner &&
        requiredAction === NewExchangeAgreementActionRequired.Complete ? (
          <LabelWithContent
            label="Host system"
            content={
              <div className={labelClassName}>
                {selectedPartnerConnector
                  ? selectedPartnerConnector.hostSystemName
                  : "-"}
              </div>
            }
          />
        ) : (
          <LabelWithContent
            label="Host System"
            content={
              <div className={labelClassName}>
                {exchangeAgreement.partnerConnector
                  ? exchangeAgreement.partnerConnector.hostSystemName
                  : "-"}
              </div>
            }
          />
        )}
      </>
    );
  }, [
    isPartner,
    requiredAction,
    selectedPartnerConnector,
    exchangeAgreement.partnerConnector,
    selectedPartnerScheduleId,
  ]);

  const RenderExecuteComponent = useCallback(() => {
    return (
      <>
        {isPartner &&
        requiredAction === NewExchangeAgreementActionRequired.Complete ? (
          <LabelWithContent
            label="Execute component"
            content={
              <div className={labelClassName}>
                {selectedPartnerConnector
                  ? selectedPartnerConnector.executionComponentText
                  : "-"}
              </div>
            }
          />
        ) : (
          <LabelWithContent
            label="Execute component"
            content={
              <div className={labelClassName}>
                {exchangeAgreement.partnerConnector
                  ? exchangeAgreement.partnerConnector.executionComponentText
                  : "-"}
              </div>
            }
          />
        )}
      </>
    );
  }, [
    isPartner,
    requiredAction,
    selectedPartnerConnector,
    exchangeAgreement.partnerConnector,
  ]);

  const isIeaViewWithCompleteActionRequired =
    !props.allowEdit &&
    exchangeAgreement.actionRequiredIfInactive ===
      NewExchangeAgreementActionRequired.Complete;

  return (
    <IEAContentBodyWithTitle
      title="Partner configuration"
      bodyHeight="h-auto"
      content={
        <>
          <div className="grid grid-cols-4 gap-y-4">
            <LabelWithContent
              label="Partner tenant"
              content={
                <div className={labelClassName}>
                  {exchangeAgreement.partnerTenant?.id}{" "}
                  {exchangeAgreement.partnerTenant?.name}
                </div>
              }
            />

            {!isIeaViewWithCompleteActionRequired && (
              <RenderConnectorNameComponent />
            )}
            {!isIeaViewWithCompleteActionRequired && (
              <RenderHostSystemComponent />
            )}
            {!isIeaViewWithCompleteActionRequired && (
              <LabelWithContent
                label="Config"
                content={
                  <div className={labelClassName}>
                    <ConfigNameDisplay
                      configId={
                        exchangeAgreement.partnerConfigId &&
                        !!exchangeAgreement.partnerConfigTitle
                          ? exchangeAgreement.partnerConfigId
                          : selectedConfigBasedOnPartnerConnectorAndSchedule?.id
                      }
                      configTitle={
                        exchangeAgreement.partnerConfigId &&
                        !!exchangeAgreement.partnerConfigTitle
                          ? exchangeAgreement.partnerConfigTitle
                          : selectedConfigBasedOnPartnerConnectorAndSchedule?.title
                      }
                      tenantId={exchangeAgreement.partnerTenant?.id}
                    />
                  </div>
                }
              />
            )}
            <LabelWithContent
              label="Exchange role"
              content={
                <div className={labelClassName}>
                  {exchangeAgreement.partnerRoleText}
                </div>
              }
            />

            {!isIeaViewWithCompleteActionRequired && (
              <div className="grid gap-4">
                <RenderScheduleIdentifierComponent />
              </div>
            )}

            {!isIeaViewWithCompleteActionRequired && <RenderExecuteComponent />}

            <PartnerRepresentativeField
              partnerTenantId={exchangeAgreement?.partnerTenant?.id}
              editConfig={{
                onPartnerRepresentativeChange:
                  handlePartnerRepresentativeChange,
                selectedRepresentatives:
                  exchangeAgreement.partnerRepresentatives,
                partnerRepresentativeIds: partnerRepresentativeIds,
                isOwner: !isPartner,
                onSavePartnerRepresentative:
                  handleSavePartnerRepresentativeClick!,
                allowEdit: isPartner && props.allowEdit,
              }}
              createConfig={{
                onValueChange: handlePartnerRepresentativeChange,
                representatives: exchangeAgreement.partnerRepresentatives.map(
                  (r) => r.id
                ),
              }}
              editStatus={
                props.isActiveIeaEdit ||
                exchangeAgreement.actionRequiredIfInactive ===
                  NewExchangeAgreementActionRequired.Complete
                  ? "create"
                  : "edit"
              }
            />
          </div>
        </>
      }
    />
  );
}
