import PromineoLink from "components/common/PromineoLink";
import { CONFIGS } from "shared/constants/RoutePathConstants";
import { checkIfCurrentUserIsSetupAdmin } from "shared/utilities/CurrentUserUtility";
import { useAppSelector } from "store/hooks";

interface Props {
  configId?: number;
  configTitle?: string;
  tenantId: number;
}

export default function ConfigNameDisplay(props: Props) {
  const currentUser = useAppSelector((store) => store.userData.mySelfResponse);
  const currentUserHasSetupAdminRole = currentUser
    ? checkIfCurrentUserIsSetupAdmin(currentUser)
    : false;

  if (!props.configId) {
    return <>-</>;
  }

  const configDisplayText = `${props.configId} ${props.configTitle}`;

  // In future we may implement viewing config coming from another tenant. In that case we will remove
  // this checking and allow hyperlink to view that config.
  const isConfigFromAnotherTenant =
    !props.tenantId ||
    !currentUser?.tenant ||
    props.tenantId !== currentUser?.tenant?.id;

  if (!currentUserHasSetupAdminRole || isConfigFromAnotherTenant) {
    return <>{configDisplayText}</>;
  }

  return (
    <PromineoLink target="_blank" to={`${CONFIGS}/${props.configId}`}>
      {configDisplayText}
    </PromineoLink>
  );
}
